@tailwind base;

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */
.highlight {
  background-image: linear-gradient(transparent 60%, rgba(253, 237, 93) 0%);
}

#hidden_form {
  display: none;
}

.mask1 {
  -webkit-mask-image: url(/assets/pages/send-it/Bottom_Shape.svg);
  mask-image: url(/assets/pages/send-it/Bottom_Shape.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat; 
}

.mask2 {
  clip-path: url(/assets/pages/send-it/Top_Shape.svg);
}

.form_builder_output {
  display: none !important;
}

.can_embed h2, .can_embed h4, #logo_wrap, label.floatlabel-label {
  display: none !important;
  padding: 0;
}

.can_embed li {
  list-style-type: none;
  padding: 6px;
}

.can_embed input {
  padding: 4px;
  width: 100%;
}

.can_embed input {
  border-width: 1px;
  border-color: darkgray;
}

.can_embed textarea {
  border-width: 1px;
  width: 100%;
  height: 100px;
}

#letter-form textarea {
  height: 700px;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @variants focus {
    /* 
      ring-no-inset is a hack to 'undo' ring-inset
      based on https://github.com/tailwindlabs/tailwindcss/discussions/3016
    */
    .ring-no-inset {
      --tw-ring-inset: ;
    }
  }
  .content-after {
    content: '';
  }
  .full-grid {
    @apply col-span-12 gap-x-3 grid grid-cols-4 justify-center w-full sm:grid-cols-6 md:grid-cols-12 lg:gap-x-6;
  }
}

@layer base {
  * {
    @apply text-primary;
  }
  h1 {
    @apply text-4xl tracking-2 uppercase;
  }
  h2 {
    @apply text-3xl tracking-1 uppercase;
  }
  h3 {
    @apply text-3xl tracking-2;
  }
  h4 {
    @apply text-2xl tracking-2;
  }
  p,
  a {
    @apply text-base tracking-normal;
  }
  @screen sm {
    h1 {
      @apply text-5xl tracking-3;
    }
    h2 {
      @apply text-3xl tracking-2;
    }
  }
  @screen md {
    h1 {
      @apply text-6xl tracking-4;
    }
    h2 {
      @apply text-5xl tracking-3;
    }
    h3 {
      @apply text-4xl tracking-2;
    }
    h4 {
      @apply text-3xl tracking-2;
    }
  }
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */
